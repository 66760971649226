import { useCallback } from 'react'

import { useKeycloak } from '@react-keycloak/web'

export const useAuthService = (): any => {
  const { keycloak } = useKeycloak()

  const login = useCallback(
    (locale: any) => {
      keycloak.login({ redirectUri: `${process.env.REACT_APP_BASE_URL}/Dashboard/` })
    },
    [keycloak]
  )

  const logout = useCallback(() => {
    keycloak?.logout()
  }, [keycloak])

  const register = useCallback(
    (locale: any) => {
      const url = keycloak?.createRegisterUrl()
      window.location.assign(url)
    },
    [keycloak]
  )

  const authenticated = (): boolean | undefined => {
    return keycloak?.authenticated
  }

  return { login, logout, register, authenticated }
}
