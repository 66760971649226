import React from 'react'
import ReactDOM from 'react-dom/client'
import { hydrate, render } from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './scss/index.scss'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const rootElement = document.getElementById('root') as HTMLElement

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <BrowserRouter>
      <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <App />
            {/* <ReactQueryDevtools initialIsOpen /> */}
            <ToastContainer position='top-right' autoClose={5000} />
          </QueryClientProvider>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </BrowserRouter>
  )
} else {
  root.render(
    <BrowserRouter>
      <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <App />
            {/* <ReactQueryDevtools initialIsOpen /> */}
            <ToastContainer position='top-right' autoClose={5000} />
          </QueryClientProvider>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </BrowserRouter>
  )
}

reportWebVitals()
