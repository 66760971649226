import axios from 'axios' // Adjust if you're using a different router
import NoSleep from 'nosleep.js'
import { lazy, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../footer/Footer'
import Modal from './Modal'
import './PromoSpecialPage.css'

const Header = lazy(() => import('header/Header'))

const PromoSpecialPage = () => {
  const [email, setEmail] = useState('')
  const [consent, setConsent] = useState(false)
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'success' | 'error'>('idle')
  const [offerDetails, setOfferDetails] = useState<{
    name: string
    description: string
    expiration: string
  } | null>(null)
  const navigate = useNavigate() // Updated hook
  const idleTimeout = useRef<NodeJS.Timeout | null>(null)
  const resetIdleTimer = () => {
    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current)
    }
    idleTimeout.current = setTimeout(() => {
      setEmail('')
      setConsent(false)
    }, 30000) // 30 seconds
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalError, setModalError] = useState(undefined)
  const [noSleep, setNoSleep] = useState<NoSleep | null>(null)

  useEffect(() => {
    const nosleep = new NoSleep()
    setNoSleep(nosleep)

    const enableNoSleep = () => {
      nosleep.enable()
      document.removeEventListener('click', enableNoSleep, false)
    }

    document.addEventListener('click', enableNoSleep, false)

    return () => {
      nosleep.disable()
    }
  }, [])
  useEffect(() => {
    // Attach event listeners
    const formElement = document.getElementById('promo-form')
    formElement?.addEventListener('input', resetIdleTimer)

    // Initialize idle timer
    resetIdleTimer()

    return () => {
      if (idleTimeout.current) {
        clearTimeout(idleTimeout.current)
      }
      formElement?.removeEventListener('input', resetIdleTimer)
    }
  }, []) // Empty dependency array to run only once on mount

  useEffect(() => {
    return () => {
      // Clean up modal timer
      if (modalTimer.current) {
        clearTimeout(modalTimer.current)
      }
    }
  }, [])

  useEffect(() => {
    // Check if the promo is active
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/subscription/pcm/promo-special`)
      .then((response) => {
        if (response.status !== 202 || !response.data) {
          console.log('Wrong responseCode -> redirect to /')
          navigate('/')
        }

        const { name, description, expiration } = response.data
        setOfferDetails({ name, description, expiration })
      })
      .catch(() => {
        console.log('Error -> redirect to /')
        // Redirect to '/' on error
        navigate('/')
      })
  }, [navigate])

  const modalTimer = useRef<NodeJS.Timeout | null>(null)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!consent) {
      alert('Please provide consent to receive the promo code.')
      return
    }
    // Handle the form submission logic
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/subscription/pcm/promo-special/send`, { eMail: email })
      .then(() => {
        setSubmissionStatus('success')
        setEmail('')
        setConsent(false)
        setIsModalOpen(true)

        if (modalTimer.current) {
          clearTimeout(modalTimer.current)
        }
        modalTimer.current = setTimeout(() => {
          setIsModalOpen(false)
        }, 5000)
      })
      .catch((error) => {
        console.error('Error sending promo code:', error.response.data.errorMessage)
        setSubmissionStatus('error')
        setIsModalOpen(true)
        setModalError(error.response.data.errorMessage)
        if (modalTimer.current) {
          clearTimeout(modalTimer.current)
        }
        modalTimer.current = setTimeout(() => {
          setIsModalOpen(false)
        }, 5000)
      })
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
    if (modalTimer.current) {
      clearTimeout(modalTimer.current)
      modalTimer.current = null
    }
  }
  if (!offerDetails) {
    // Optionally, show a loading state
    return <div>Loading...</div>
  }

  return (
    <div className='promo-page'>
      <Header />
      <div className='promo-container my-60'>
        <h1 className='promo-title'>{offerDetails.name || ''}</h1>
        <p className='promo-description'>{offerDetails.description || ''}</p>
        <p className='promo-expiration'>
          Offer valid until: {new Date(offerDetails.expiration || '').toLocaleDateString()}
        </p>

        {/* Modal Popup */}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          {submissionStatus === 'success' ? (
            <div className='modal-content-success'>
              <p>Your promo code has been sent to your email!</p>
            </div>
          ) : (
            <div className='modal-content-error'>
              <p>
                There was an error sending the promo code:
                <br />
                {modalError}
              </p>
            </div>
          )}
        </Modal>

        <form id='promo-form' onSubmit={handleSubmit} className='promo-form'>
          <div className='form-group'>
            <label htmlFor='email'>Email Address:</label>
            <input
              id='email'
              type='email'
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group checkbox-group'>
            <label htmlFor='consent'>
              <input
                id='consent'
                type='checkbox'
                checked={consent}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConsent(e.target.checked)}
                required
              />
              I consent to receive the promo code via email.
            </label>
          </div>
          <p className='note'>
            <strong>Note:</strong> The email is only used to send the promo code and will not be saved or shared with
            any partner.
          </p>
          <button type='submit' className='submit-button'>
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default PromoSpecialPage
