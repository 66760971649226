import React from 'react'

import theRacingHubLog from 'assets/images/The_Racing_Hub_Log.png'
import { BsDiscord } from 'react-icons/bs'
import { useAuthService } from 'services/authService'
import { useNavigateRetainParameters, useGetNavBarOptions } from 'utils/hooks'
import { useIntl } from 'react-intl'

interface FooterElem {
  displayText: string
  onClick?: () => void
}

const Footer: React.FC = () => {
  const footerTextRender = (arr: Array<FooterElem>) => {
    return arr.map((elem, index: number) => (
      <div
        style={elem.onClick ? { cursor: 'pointer' } : {}}
        className={`mb-16 text-start `}
        onClick={elem.onClick}
        key={`footer-text-${index}`}
      >
        <span className={`${index === 0 ? 'text-white-50' : 'text-white'}`}>{elem.displayText}</span>
      </div>
    ))
  }
  const { register } = useAuthService()
  const { formatMessage } = useIntl()

  const navBarOptions = useGetNavBarOptions()
  const navigateRetainParameters = useNavigateRetainParameters()

  const footerColHelp: FooterElem[] = [
    { displayText: 'HELP' },
    // TODO: either use reference or remove
    { displayText: 'FAQ', onClick: () => navigateRetainParameters('memberships', [0, 1900]) },
    { displayText: 'Contact', onClick: () => navigateRetainParameters('memberships', [0, 2400]) },
  ]
  const footerColNavigation: FooterElem[] = [
    { displayText: 'PAGES' },
    ...navBarOptions.map((e) => {
      return { displayText: e.displayName, onClick: () => navigateRetainParameters(e.address) }
    }),
  ]
  const footerColLegal: FooterElem[] = [
    { displayText: 'LEGAL' },
    { displayText: 'Legal Notice', onClick: () => (window.location.href = 'https://www.efs-techhub.com/impressum') },
    { displayText: 'Terms of Use', onClick: () => (window.location.href = '/docs/terms_of_use.pdf') },
    { displayText: 'Privacy Policy', onClick: () => (window.location.href = '/docs/privacy_policy.pdf') },
    {
      displayText: 'Cancellation Rights',
      onClick: () => (window.location.href = '/docs/cancellation_policy_and_form.pdf'),
    },
  ]

  return (
    <>
      <div className='float-start w-100 bg-customDark py-60'>
        <div className='container'>
          <div className='widget-section'>
            <div className='row clearfix'>
              <div className='col-lg-6 col-md-12 col-sm-12 footer-column'>
                <div className='footer-widget about-widget'>
                  <div className='widget-title d-flex'>
                    {/*border-r-2 */}
                    <div>
                      <img className='me-24 p-w-1 ' src={theRacingHubLog} alt='' />
                    </div>
                  </div>
                  <br />
                  <div className='d-flex justify-content-start my-20'>
                    <button
                      className='text-white p-16 fs-14 rounded border-0 float-end bg-lightDark'
                      onClick={() => (window.location.href = formatMessage({ id: 'Discord.Link' }))}
                    >
                      <BsDiscord className='me-4' size={14} color='white' />
                      <span>Join our Discord</span>
                    </button>
                  </div>
                </div>
              </div>
              <hr className='text-white p-b-none' />

              <div className='col-lg-6 col-md-12 col-sm-12 footer-column'>
                <div className='row clearfix'>
                  <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 column'>
                    <div className='footer-widget links-widget'>
                      <div>{footerTextRender(footerColHelp)}</div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 column'>
                    <div className='footer-widget contact-widget ml-40'>{footerTextRender(footerColNavigation)}</div>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 column'>
                    <div className='footer-widget contact-widget'>{footerTextRender(footerColLegal)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom centred'>
            <hr className='text-white' />
            <div className='text-white w-100 ps-20'>
              <span className=' float-start text-white'>
                ©The Racing Hub 2023. All rights reserved. The Racing Hub is created by e:fs TechHub GmbH.
                <br />
                <a href='https://www.efs-techhub.com'>www.efs-techhub.com</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
