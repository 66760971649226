import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAuthService } from 'services/authService'
import { useKeycloak } from '@react-keycloak/web'

export const useGetNavBarOptions = () => {
  const { formatMessage } = useIntl()
  const navBarOptions: { address: string; displayName: string }[] = [
    { address: 'home', displayName: formatMessage({ id: 'Header.Tabs.Home' }) },
    { address: 'about', displayName: formatMessage({ id: 'Header.Tabs.About' }) },
    { address: 'memberships', displayName: formatMessage({ id: 'Header.Tabs.Memberships' }) },
    { address: 'guide', displayName: formatMessage({ id: 'Header.Tabs.Guide' }) },
  ]
  return navBarOptions
}

export const useNavigateRetainParameters = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (address: string, navCoords: [number, number] | undefined = [0, 0]) => {
    navigate(`/${address}${location.search}`)
    if (navCoords) window.scrollTo(...navCoords)
  }
}

export const useGuideUser = () => {
  const { login } = useAuthService()
  const { keycloak } = useKeycloak()

  const guideUser = () => {
    if (keycloak.authenticated) window.location.href = '/Dashboard'
    else login()
  }

  return guideUser
}
