// Modal.tsx
import React from 'react'
import './Modal.css'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null
  console.log(children)
  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        {children}
        <button onClick={onClose} className='modal-close-button'>
          Close
        </button>
      </div>
    </div>
  )
}

export default Modal
